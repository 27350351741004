import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  url: string = environment.url;
  basic: string = environment.basic;
  public user: any = '';

  public basicHeaders = new HttpHeaders({'Authorization' : this.basic });
  public fixedHeader;
  constructor(private httpClient: HttpClient, private route: Router, 
    private cookiesService: CookiesService
    ) { }
 isLogedIn() {
  if (this.cookiesService.getCookie('access-Token-Cambio-Posadas') == ''){
    return false;
  }
  if (this.cookiesService.getCookie('access-Token-Cambio-Posadas') !== null) {
      if (this.cookiesService.getCookie('access-Token-Cambio-Posadas') !== undefined) {
        //console.log(this.cookiesService.get('access-Token-Cambio-Posadas'));
        return true;
      }
  // if (localStorage.getItem('access-Token-Cambio-Posadas') == ''){
  //   return false;
  // }
  // if (localStorage.getItem('access-Token-Cambio-Posadas') !== null) {
  //     if (localStorage.getItem('access-Token-Cambio-Posadas') !== undefined) {
  //       //console.log(this.cookiesService.get('access-Token-Cambio-Posadas'));
  //       return true;
  //     }
  //  }
  }
  //console.log('NO tiene cookie');
//   return false;
}
getOauth(email: string, password: string) {
  const formData = new FormData();

  formData.append('grant_type', 'password' );
  formData.append('username', email );
  formData.append('password', password );

  return this.httpClient.post(this.url +'/oauth/token', formData, {
    headers: this.basicHeaders,
    observe: 'body',
    responseType: 'json'
  });

}

getOauthFacebook(token: string) {
  const formData = new FormData();
  formData.append('facebookToken', token );

  return this.httpClient.post(this.url +'/oauth/token', // primero la URL
      formData, // desp la info que quiero guardar
      {headers: this.basicHeaders});

}
userForgotPassword(email) {
  const formData = new FormData();
  formData.append('email', email );
  return this.httpClient.post(this.url +'/public/user/password_recovery', formData, {
    headers: this.basicHeaders,
    observe: 'body',
    responseType: 'json'
  });
}
// setea los datos del urtuasio en el servico
setUserData(){
  this.getUserAuth().subscribe(
    (r:any)=>{
      this.user = r;
      //console.log(this.user);
    },
    (error)=>{
      console.log(error);
    }
  );
}
getUserData(){
  return this.user;
}
changeFirstSessionStatus(){
  let __formData = new FormData();
  __formData.append('firstStartSession', 'false');
  return this.httpClient.post(this.url +'/public/user/editFirstStartSession', // primero la URL
  __formData, // desp la info que quiero guardar
  {headers: this.fixedHeader});
  
}

// OBTIENE LOS DATOS DEL USUSARIO YA LOGEADO
getUserAuth():any {
  //console.log(this.fixedHeader);
  //console.log(this.fixedHeader.get('Authorization'));
  return  this.httpClient.get(this.url +'/public/user/mine', {headers: this.fixedHeader});

}
registerUser(email, name, lastName, userType, password){
  const formData = new FormData();
  formData.append('email', email);
    formData.append('lastName', lastName);
    formData.append('name', name);
    formData.append('password', password);
    formData.append('userType', userType);
    return this.httpClient.post(this.url + '/public/user' ,formData, {headers: this.basicHeaders})
}
setBearerToken(token: any): void {
  // let d = new Date();
  // let now =  new Date(d.getTime() - (d.getTimezoneOffset() * 60 * 1000 - 1000000)); 
   
  //this.cookiesService.set( 'access-Token-Cambio-Posadas', 'Bearer ' + token ,0.1);
  //localStorage.setItem('access-Token-Cambio-Posadas', 'Bearer ' + token);
  this.fixedHeader = new HttpHeaders().set('Authorization', 'Bearer ' + token);
}
setBearerTokenLocal(tokenAndBearer){
  //  let d = new Date();
  //  let now =  new Date(d.getTime() - (d.getTimezoneOffset() * 60 * 1000 - 1000000));  
  //  console.log(now)
  //this.cookiesService.set( 'access-Token-Cambio-Posadas',  tokenAndBearer, 0.1 );
  this.fixedHeader = new HttpHeaders().set('Authorization', tokenAndBearer);
}
removeToken() {
  this.cookiesService.deleteCookie();
  //localStorage.removeItem('access-Token-Cambio-Posadas');
}
logout() {
  this.removeToken();        
  this.fixedHeader.delete('Authorization');  
  this.route.navigate(['']);
}
newsletter(mail) {
  const formData = new FormData();
    formData.append('email',mail);
    formData.append('message', 'newsletter');
    formData.append('name', 'newsletter');
    formData.append('title', 'newsletter');
    return this.httpClient.post(this.url+'/public/web_contact', formData, ({ headers: this.basicHeaders}));
}
}
