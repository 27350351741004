import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(private httpClient:HttpClient) { }


  sendForm(name:any, email:any, phone:any, message:any) {
    
    const url = 'http://mailingsystem-env.jtj3npc4yj.sa-east-1.elasticbeanstalk.com/public/send_mail';
    const formData = new FormData();
    formData.append('clientName', 'Cambio Posadas');
    formData.append('senderName', name);
    formData.append('senderEmail', email);
    formData.append('message', 
    `Nombre: ${name} <br/>
    Mail: ${email} <br/>
    Teléfono: ${phone} <br/>
    Mensaje: ${message}`);
    formData.append('senderPhone', phone);
    formData.append('receiverEmail', 'info@cambioposadas.com.ar');
    formData.append('subject', 'Contacto desde la web Cambio Posadas');

    return this.httpClient.post(url, formData);
  }
}
