import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CotizadorComponent } from '../cotizador/cotizador.component';
import { MyCurrencyPipe } from '../pipes/my-currency.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MyCurrencyFormatterDirective } from '../directives/my-currency-formatter.directive';
import { GooglePlacesDirective } from '../directives/google-places.directive';

@NgModule({
    imports: [ 
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    declarations: [CotizadorComponent,MyCurrencyPipe,MyCurrencyFormatterDirective,GooglePlacesDirective],
    providers: [//MyCurrencyPipe
    ],
    exports: [CotizadorComponent, CommonModule,MyCurrencyFormatterDirective,GooglePlacesDirective
       // MyCurrencyPipe
    ],
})
export class SharedModule {
    static forRoot() {
        return {
            ngModule: SharedModule,
            providers: [ //services that you want to share across modules
                
            ]
        }
    }
}