import { Component, OnInit } from '@angular/core';
import { trigger, transition, animate, style, state } from '@angular/animations'
@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({opacity: 0, display: 'none',}),
        animate('300ms ease-in', style({display: 'block', opacity: 1, }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({opacity: 0, display: 'none'}))
      ])
    ])
  ]
})
export class MapsComponent implements OnInit {
  public maps: boolean = true;
  constructor() { }

  ngOnInit() {
  }
  changeMap(value){
    value == 'quintana'? this.maps = false: this.maps = true;
  }
}
