import { Injectable, OnDestroy } from '@angular/core';
import { CustomModalService } from './custom-modal.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesService implements OnDestroy {
  private interval;
  constructor(private customModal: CustomModalService) { }

  setCookieInterval(){
     this.interval = setInterval(() => {
    this.checkCookie(); 
  }, 60 * 1000);
  }
 

  setCookie( cvalue ) {
    var d = new Date();
    d.setTime(d.getTime() + (15 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = 'access-Token-Cambio-Posadas' + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  checkCookie() {
    var user = this.getCookie("access-Token-Cambio-Posadas");
    //console.log(JSON.parse(user));
    if (user != "") {
      // console.log("Welcome again " + user);
    } else {
      console.log('nada');
      this.customModal.setBehaviorView({ modal: true, text: 'Se esta por cecrrar la sesion. Queres continuar?', title: 'Cerrar Sesión?', type: 'warning', timer: true });
      this.clearCookieInterval();
    }
  }
  deleteCookie() {
    document.cookie = "access-Token-Cambio-Posadas" +'=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  clearCookieInterval(){
    clearInterval(this.interval);
  }
  
  ngOnDestroy(){
    if (this.interval) {
     this.clearCookieInterval();
    }
  }
}
