import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../services/general.service';
import { CurrencyService } from '../services/currency.service';

@Component({
  selector: 'app-cotizaciones',
  templateUrl: './cotizaciones.component.html',
  styleUrls: ['./cotizaciones.component.scss']
})
export class CotizacionesComponent implements OnInit {
  diccionario = []; // todas las monedas menos ARS USD BRL EUR
  monedasPrincipales = []; // Todas las Monedas menos ARS
  constructor( private cService: CurrencyService) { }

  ngOnInit() {

    this.cService.getCurrencies().subscribe(
      (response: any) => {
        //console.log(response);
        this.settingDictionary(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  settingDictionary(arr) {
    // esta funcion filtra el array y carga ambar variables de monedas
    this.diccionario = [];
    this.monedasPrincipales = [];
    //console.log('entro al set Dictionary')
    for (let i = 0; i < arr.length; i++) {
      // arr[i].id != 'USD' && arr[i].id != 'EUR' && arr[i].id != 'BRL' &&
      if ( arr[i].id != 'ARS') {
        this.diccionario.push({ 'id': arr[i].id, 'name': arr[i].name, 'ask': arr[i].providers[0].ask, 'bid': arr[i].providers[0].bid });
      } else if (arr[i].id != 'ARS') {
        this.monedasPrincipales.push({
          'id': arr[i].id,
          'name': arr[i].name,
          'askCambio': arr[i].providers[0].ask,
          'bidCambio': arr[i].providers[0].bid,
          'askBancos': arr[i].providers[1].ask,
          'bidBancos': arr[i].providers[1].bid,
          'askCasas': arr[i].providers[2].ask,
          'bidCasas': arr[i].providers[2].bid,
        });
      }
    }
  }

}
