import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'perfil',
    loadChildren: './profile/profile.module#ProfileModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'firstLog',
    loadChildren: './first-log/first-log.module#FirstLogModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'reconocer',
    loadChildren: './reconocer/reconocer.module#ReconocerModule',
  },
  {
    path: 'comprar',
    loadChildren: './comprar/comprar.module#ComprarModule',
  },
  {
    path: 'vender',
    loadChildren: './vender/vender.module#VenderModule',
  },
  {
    path: 'empresas',
    loadChildren: './empresas/empresas.module#EmpresasModule',
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    //scrollPositionRestoration: 'enabled',
    //anchorScrolling: 'enabled',
    //scrollOffset: [0, -1000]
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
