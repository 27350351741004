import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-banner',
  templateUrl: './mobile-banner.component.html',
  styleUrls: ['./mobile-banner.component.scss']
})
export class MobileBannerComponent implements OnInit {
  showBanner:boolean = false;
  url: string = '';
  constructor() { }

  ngOnInit() {
    //console.log(this.deviceOS());
    if (window.innerWidth < 500) {
      this.showBanner = true;
      if (this.deviceOS() == 'iphone'){
        this.url = 'https://itunes.apple.com/ar/app/cambio-posadas/id1345520805?mt=8';
      }else if (this.deviceOS() == 'android') {
        this.url = 'https://play.google.com/store/apps/details?id=com.paisanoscreando.cambioposadas';
      }
      setTimeout(()=>{
        this.showBanner = false;
      },30000)
    }
  }
  deviceOS() {
    var useragent = navigator.userAgent;

    if(useragent.match(/Android/i)) {
        return 'android';
    } else if(useragent.match(/webOS/i)) {
        return 'webos';
    } else if(useragent.match(/iPhone/i)) {
        return 'iphone';
    } else if(useragent.match(/iPod/i)) {
        return 'ipod';
    } else if(useragent.match(/iPad/i)) {
        return 'ipad';
    } else if(useragent.match(/Windows Phone/i)) {
        return 'windows phone';
    } else if(useragent.match(/SymbianOS/i)) {
        return 'symbian';
    } else if(useragent.match(/RIM/i) || useragent.match(/BB/i)) {
        return 'blackberry';    
    } else if(navigator.vendor.match(/Google Inc/)) {
      return 'chrome';
    } else {
        return false;
    }
}
goTo(){
  document.location.href = this.url;
}
}
