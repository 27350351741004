import { Component, OnInit, HostListener } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { CustomModalService } from '../services/custom-modal.service';
import { CurrencyService } from '../services/currency.service';
import { GeneralService } from '../services/general.service';
import { Router } from '@angular/router';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  monedaState = false;
  herramientaState = false;

  public showBurgerMenu: boolean = false;
  public monedas  = [{ask: 0,bid: 0}];
  constructor(
    private modalService: ModalService,
    private gServbice: GeneralService,
    private customModalService: CustomModalService,
    private currencyService: CurrencyService,
    private router: Router) {}

  ngOnInit() {
    this.currencyService.getCurrencies().subscribe(
      (r: any) => {
        this.settingMonedas(r);
      },
      (error: any) => {
        console.log(error);
      },

    );
    this.repeater();
  }
  @HostListener("window:scroll", ['$event'])
  onWindowScroll(ev) {
    //console.log(ev);
    ev.srcElement.scrollingElement.scrollTop >  55 ? document.querySelector('.navbar__2').classList.add('fixed-navbar'): document.querySelector('.navbar__2').classList.remove('fixed-navbar');
    
  }
  showLogin(){
    this.modalService.setBehaviorView({modal:true});
  }
  toggleBurgerMenu(){
    // this.showBurgerMenu ? this.showBurgerMenu = false : this.showBurgerMenu = true;
    this.showBurgerMenu = !this.showBurgerMenu
  }

  onActivate(event) {
    window.scroll(0,0);
  }
  herramientaHandler(){
    this.monedaState = false;
    this.herramientaState = !this.herramientaState;
  }
  monedaHandler(){
    this.herramientaState = false;
    this.monedaState = !this.monedaState;
  }
  alert(){
    this.customModalService.setBehaviorView({
      modal: true,
      title: 'Felicitaciones!',
      type: 'success',
      text: 'Ahi va Ana a darle bola al luchi Malo >= O',
      timer: false
    });
  }
  isLoggedIn(){
    return this.gServbice.isLogedIn();
  }
  giveUserName(){
    if (this.gServbice.user != ''){
      let fullName = this.gServbice.user.fullName;
      return fullName;
    }else {
      let fullName = 'Cargando';
      return fullName;
    }
    
  }
  logout(){
    this.toggleBurgerMenu();
    this.customModalService.setBehaviorView(
      {
        modal: true,
        title: 'Cerrar Sesión',
        type: 'logout',
        text: '¿Estás seguro que deseas cerrar sesión?',
        timer: false
      }
    );
  }
  repeater() {
    //console.log('entro al repeater');
    setInterval(() => {
      this.currencyService.getCurrencies().subscribe(
        (response: any) => {
          this.settingMonedas(response);
          //console.log('respuesta de header', response);       
        },
        (error) => {
          console.log(error);
        }
      );
    }, 120000);
  }
  navegate(__direction, __fragment){
    this.toggleBurgerMenu();
    __fragment == undefined ? this.router.navigate([__direction]) : this.router.navigate([__direction], { fragment: __fragment }, );
    scrollTo(0,0);
  }
  settingMonedas(array) {
    this.monedas = [];
    array.map(element => {
      if (element.id == 'USD') {
        this.monedas = [{
          ask: element.providers[0].ask,
          bid: element.providers[0].bid
        }];
      }
    });
  }
}
