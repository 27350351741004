import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileInfoService {

  constructor(private httpClient: HttpClient, private gService: GeneralService) { }

  getUserInfo(){
      return  this.httpClient.get(this.gService.url +'/public/user/mine', {headers: this.gService.fixedHeader});
  }
  getUserAlerts(){
    return  this.httpClient.get(this.gService.url +'/public/alert', {headers: this.gService.fixedHeader});
  }
  getUserTransactions(number){
    return  this.httpClient.get(this.gService.url +'/public/transaction?page='+number+'&sort=id,desc', {headers: this.gService.fixedHeader});
  }
  deleteAlert(id){
    return this.httpClient.delete(this.gService.url + '/public/alert?alertId='+id , { headers: this.gService.fixedHeader } );
  }
  createAlert(currencyId, isBid ,value ){
    let formData = new FormData();
    formData.append('currencyId',currencyId );
    formData.append('isBid',isBid );
    formData.append('value',value );
    return this.httpClient.post(this.gService.url + '/public/alert',formData, { headers: this.gService.fixedHeader } );
  }
  createNewUser(__formObj, userType){
    let __formData = new FormData();
    __formData.append('email', __formObj.email);
    __formData.append('name', __formObj.name);
    __formData.append('lastName ', __formObj.lastName);
    __formData.append('userType ', userType);
    __formData.append('password ', __formObj.password);
    return this.httpClient.post(this.gService.url + '/public/user',__formData, { headers: this.gService.basicHeaders } );
  
  }
  editUserWithDate(__formDataObject: any, name, lastName){
    let formData = new FormData();
    formData.append('name', name);
    formData.append('lastName', lastName);
    //Form1
    this.withData(__formDataObject.localidad) ? formData.append('city', __formDataObject.localidad) : '';
    this.withData(__formDataObject.calle) ? formData.append('address', __formDataObject.calle) : '';
    this.withData(__formDataObject.codigoPostal) ? formData.append('postalCode', __formDataObject.codigoPostal): '';
    this.withData(__formDataObject.provincia) ? formData.append('province', __formDataObject.provincia) : '';
    this.withData(__formDataObject.birthDate) ? formData.append('birthDate', __formDataObject.birthDate) : '';
    this.withData(__formDataObject.birthPlace) ? formData.append('birthPlace', __formDataObject.birthPlace) : '';
    this.withData(__formDataObject.phoneNumber) ? formData.append('phoneNumber', __formDataObject.phoneNumber) : '';
    this.withData(__formDataObject.DNI) ? formData.append('DNI', __formDataObject.DNI) : '';
    this.withData(__formDataObject.cuilCuitCdi) ? formData.append('cuilCuitCdi', __formDataObject.cuilCuitCdi) : '';
    return this.httpClient.post(this.gService.url + '/public/user/edit', formData , {headers: this.gService.fixedHeader} );
  }
  editUserData(__formDataObject: any, name, lastName){
    // console.log(__formDataObject.pep);
    
    let formData = new FormData();
    //Variables necesarias
    formData.append('name', name);
    formData.append('lastName', lastName);

    //Form2
    this.withData(__formDataObject.localidad) ? formData.append('city', __formDataObject.localidad) : '';
    this.withData(__formDataObject.calle) ? formData.append('address', __formDataObject.calle) : '';
    this.withData(__formDataObject.codigoPostal) ? formData.append('postalCode', __formDataObject.codigoPostal): '';
    this.withData(__formDataObject.provincia) ? formData.append('province', __formDataObject.provincia) : '';
    this.withData(__formDataObject.birthDate) ? formData.append('birthDate', __formDataObject.birthDate) : '';
    
    //Form3
    this.withData(__formDataObject.civilStatus) ? formData.append('civilStatus', __formDataObject.civilStatus) : '';
    this.withData(__formDataObject.residencia) ? formData.append('userType', __formDataObject.residencia) : '';
    this.withData(__formDataObject.activityType) ? formData.append('activityType', __formDataObject.activityType) : '';


    //Form 4
    this.withData(__formDataObject.obligado) ? formData.append('sujetoObligado', __formDataObject.obligado) : '';
    this.withData(__formDataObject.pep) ? formData.append('pep', __formDataObject.pep) : '';
    
    
    

    
    
    
    
    return this.httpClient.post(this.gService.url + '/public/user/editWithoutDates', formData , {headers: this.gService.fixedHeader} );
  }
  withData(obj:any){
    // console.log(obj);
    if(obj != null && obj != undefined && obj != ''){
      return true
    } else {
      return false;
    } 
  }
  editUserImage(what,file, name, lastName){
    let formData = new FormData();
    formData.append('name', name);
    formData.append('lastName', lastName);
    what == 'dniFront' ? formData.append('dniFront',file) : '';
    what == 'imageProfile' ? formData.append('imageProfile',file) : '';
    what == 'dniBack' ? formData.append('dniBack',file) : '';
    
    return this.httpClient.post(this.gService.url + '/public/user/editWithoutDates', formData , {headers: this.gService.fixedHeader} );
  }

  getDateToSend(date){
    let __finalDate;
    let __month = '';
    let __day = '';

    if(date.getMonth() +1 < 10) {
       __month = '0'+ (date.getMonth() +1);
    }else{
       __month = date.getMonth() +1;
    }
    if(date.getDate() < 10) {
      __day = '0'+ (date.getDate());
   }else{
    __day = date.getDate();
   }
    const __year = date.getFullYear();
    __finalDate = __year + '-'+ __month +'-'+ __day;
    return __finalDate;
  }
}
