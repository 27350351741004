import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CustomModalService } from '../services/custom-modal.service';
import { trigger, transition, animate, keyframes, style } from '@angular/animations';
import { GeneralService } from '../services/general.service';
import { CookiesService } from '../services/cookies.service';

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('* => *', [
        animate(700, keyframes([
          style({
            transform: 'scale(0)',
            opacity: 0,
            offset: 0
          }),
          style({
            transform: 'scale(0.5)',
            offset: 0.5
          }),
          style({
            transform: 'scale(1.15)',
            opacity: 1,
            offset: 0.75
          }),
          style({
            transform: 'scale(1)',
            opacity: 1,
            offset: 1
          })
        ]))
      ]),
    ]),
  ]
})
export class CustomModalComponent implements OnInit {
  public showCustomModal: boolean = true;

  public text: string = '';
  public type: string = '';
  public title: string = '';
  public timer: boolean = false;
  private timeOut;
  public timeLeft: number = 31;
  private t;


  @ViewChild('customModal') customModal: ElementRef;
  constructor(private customModalService: CustomModalService, private gService: GeneralService, private cookieService: CookiesService) { }

  ngOnInit() {
    this.customModalService.getBehaviorView().subscribe(v => {
      this.showCustomModal = v['modal'];
      this.text = v['text'];
      this.title = v['title'];
      this.type = v['type'];
      this.timer = v['timer'];
      this.timer ? this.setTimeOut() : '';
        });
  }
  close(){
    this.customModalService.setBehaviorView({ modal: false, title: '', type: '', text: '', timer: false });
  }
  overlayClicked(__event) {
    if(__event.path != undefined){
      if(__event.path.indexOf(this.customModal.nativeElement) === -1){
        this.customModalService.setBehaviorView({ modal: false, title: '', type: '', text: '', timer: false  });
      }
    }else{
      __event.target.children[0] === this.customModal.nativeElement ? this.customModalService.setBehaviorView({ modal: false, title: '', type: '', text: '', timer: false  }): '';
    }
    
  }
  logout(){
    this.close();
    this.gService.logout();
    this.cookieService.clearCookieInterval();
    clearTimeout(this.t);
    this.timeLeft = 31;
    
  }
  setTimeOut(){
    console.log('entro en la funcion del time out');
    this.timedCount();
    this.timeOut = setTimeout(() => {
      console.log('en 30 segundos se deberia ir');
      this.logout();
    }, 30000);
  }
  cancelTimeOut(){
    this.close();
    clearTimeout(this.timeOut);
    clearTimeout(this.t);
    this.timeLeft = 31;
    this.cookieService.setCookie('cambio posadas cookie');
    this.cookieService.setCookieInterval();
  }
  timedCount() {
    
    this.timeLeft --;
    console.log(this.timeLeft, 'tiempo restante');
    this.t = setTimeout(()=>{this.timedCount()}, 1000);
  }

}
