import { Component, OnInit, OnDestroy } from '@angular/core';
import { GeneralService } from './services/general.service';
import { CookiesService } from './services/cookies.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Cambio Posadas';
  
  constructor(private gService: GeneralService, private cookieService: CookiesService){
    if(this.gService.isLogedIn()){
      //console.log('token desde la localstorage',localStorage.getItem('access-Token-Cambio-Posadas'));
      const token = this.cookieService.getCookie('access-Token-Cambio-Posadas');
      this.gService.setBearerToken(token);
      //this.gService.setBearerTokenLocal(localStorage.getItem('access-Token-Cambio-Posadas'));
      this.gService.setUserData(); 
      this.cookieService.setCookie(token);
      this.cookieService.setCookieInterval();
    }
  }
  ngOnInit(){
    
  }
}

