import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MobileBannerComponent} from './mobile-banner/mobile-banner.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ExpandMenuDirective } from './directives/expand-menu.directive';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { HttpClientModule } from '@angular/common/http';
import { AppsComponent } from './apps/apps.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { HowDoesItWorksComponent } from './how-does-it-works/how-does-it-works.component';
import { ReconocerComponent} from './reconocer/reconocer.component';
import { UsComponent } from './us/us.component';
import { MapsComponent } from './maps/maps.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalComponent } from './modal/modal.component';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { HeaderComponent } from './header/header.component';
import { MainComponent } from './main/main.component';
import { AuthGuard } from './guard/auth.guard';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { MyCurrencyPipe } from './pipes/my-currency.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { CookieService } from 'ngx-cookie-service';
import { GooglePlacesDirective } from './directives/google-places.directive';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { ProfileLoadingService } from './services/profile-loading.service';
import { SharedModule } from './shared/shared.module';
import { ContactUsComponent } from './contact-us/contact-us.component';


export class DefaultIntl extends OwlDateTimeIntl  {
  /** A label for the up second button (used by screen readers).  */
  upSecondLabel= 'Add a second';

  /** A label for the down second button (used by screen readers).  */
  downSecondLabel= 'Minus a second';

  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel= 'Un minuto menos';

  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel= 'Un minuto más';

  /** A label for the up hour button (used by screen readers).  */
  upHourLabel= 'Una hora más';

  /** A label for the down hour button (used by screen readers).  */
  downHourLabel= 'Una hora menos';

  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel= 'Mes anterior';

  /** A label for the next month button (used by screen readers). */
  nextMonthLabel= 'Proximo mes';

  /** A label for the previous year button (used by screen readers). */
  prevYearLabel= 'Año anterior';

  /** A label for the next year button (used by screen readers). */
  nextYearLabel= 'Proximo año';

  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel= '21 años Anteriores';

  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel= 'Proximos 21 años';

  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel= 'Cambiar vista de mes';

  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel= 'Elegi MEs y año';

  /** A label for the cancel button */
  cancelBtnLabel= 'Cancelar';

  /** A label for the set button */
  setBtnLabel= 'Seleccionar';

  /** A label for the range 'from' in picker info */
  rangeFromLabel= 'Desde';

  /** A label for the range 'to' in picker info */
  rangeToLabel= 'Hasta';

  /** A label for the hour12 button (AM) */
  hour12AMLabel= 'AM';

  /** A label for the hour12 button (PM) */
  hour12PMLabel= 'PM';
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    MobileBannerComponent,
    ExpandMenuDirective,
    CotizacionesComponent,
    AppsComponent,
    OurServicesComponent,
    HowDoesItWorksComponent,
    ReconocerComponent,
    UsComponent,
    MapsComponent,
    ModalComponent,
    CustomModalComponent,
    HeaderComponent,
    MainComponent,
    ContactUsComponent,
    //CotizadorComponent,
    //MyCurrencyPipe,
    //GooglePlacesDirective,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),

  ],
  exports: [
    SharedModule
  ],
  providers: [AuthGuard, MyCurrencyPipe,CookieService,ProfileLoadingService,
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'es'},
    {provide: OwlDateTimeIntl, useClass: DefaultIntl}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
