import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {
  @ViewChild('content1') conetenedor1: ElementRef;
  @ViewChild('content2') conetenedor2: ElementRef;
  @ViewChild('content3') conetenedor3: ElementRef;
  constructor() { }
  flex = 1;

  ngOnInit() {
  }
  changeStyle(ev, who){
    
    switch (who) {
      

      case 1:
        this.conetenedor1.nativeElement.style.flex = 2;
        this.conetenedor1.nativeElement.style.transform = 'scale(1.03)';
        this.conetenedor2.nativeElement.style.flex = 1;
        this.conetenedor2.nativeElement.style.transform = 'scale(1.0)';
        this.conetenedor3.nativeElement.style.flex = 1;
        this.conetenedor3.nativeElement.style.transform = 'scale(1.0)';
        break;
        case 2:
        this.conetenedor1.nativeElement.style.flex = 1;
        this.conetenedor1.nativeElement.style.transform = 'scale(1.0)';
        this.conetenedor2.nativeElement.style.flex = 2;
        this.conetenedor2.nativeElement.style.transform = 'scale(1.03)';
        this.conetenedor3.nativeElement.style.flex = 1;
        this.conetenedor3.nativeElement.style.transform = 'scale(1.0)';
        break;
        case 3:
        this.conetenedor1.nativeElement.style.flex = 1;
        this.conetenedor1.nativeElement.style.transform = 'scale(1.0)';
        this.conetenedor2.nativeElement.style.flex = 1;
        this.conetenedor2.nativeElement.style.transform = 'scale(1.0)';
        this.conetenedor3.nativeElement.style.flex = 2;
        this.conetenedor3.nativeElement.style.transform = 'scale(1.03)';
        break;
    
      default:
        break;
    }
  }

}
