import { Component, OnInit, AfterViewInit, ElementRef, NgZone, Input } from '@angular/core';
import { trigger, state, style, transition, animate, keyframes, query, animateChild } from '@angular/animations';
import { MyCurrencyPipe } from '../pipes/my-currency.pipe';
import {  Router, NavigationEnd } from '@angular/router';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { GeneralService } from '../services/general.service';
import { ProfileInfoService } from '../services/profile-info.service';
import { CurrencyService } from '../services/currency.service';
import { CustomModalService } from '../services/custom-modal.service';
import { ModalService } from '../services/modal.service';
import { CookiesService } from '../services/cookies.service';
import swal  from 'sweetalert2';

@Component({
  selector: 'app-cotizador',
  templateUrl: './cotizador.component.html',
  styleUrls: ['./cotizador.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200),
        query('@slideInOut1', animateChild(), { optional: true }),
        query('@slideInOut2', animateChild(), { optional: true }),
        query('@slideInOut3', animateChild(), { optional: true }),
        query('@slideInOut4', animateChild(), { optional: true }),
        query('@slideInOut5', animateChild(), { optional: true })
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(500, style({ opacity: 0 })))
    ]),
    trigger('slideInOut1', [
      transition(':enter', [
        style({ transform: 'translateX(-500px)' }),
        animate(200, keyframes([
          style({
            opacity: 0,
            offset: 0.375
          }),
          style({
            transform: 'translateX(-50px)',
            offset: 0.6
          }),
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            offset: 1
          })
        ]))
      ]),
      transition(':leave', [
        animate(2000, style({ transform: 'translateX(-100%)' }))
      ]),
    ]),
    trigger('slideInOut2', [
      transition(':enter', [
        style({ transform: 'translateX(-500px)' }),
        animate(200, keyframes([
          style({
            opacity: 0,
            offset: 0.375
          }),
          style({
            transform: 'translateX(-50px)',
            offset: 0.6
          }),
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            offset: 1
          })
        ]))
      ]),
      transition(':leave', [
        animate(2000, style({ transform: 'translateX(-100%)' }))
      ]),
    ]),
    trigger('slideInOut3', [
      transition(':enter', [
        style({ transform: 'translateX(-500px)' }),
        animate(200, keyframes([
          style({
            opacity: 0,
            offset: 0.375
          }),
          style({
            transform: 'translateX(-50px)',
            offset: 0.6
          }),
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            offset: 1
          })
        ]))
      ]),
      transition(':leave', [
        animate(2000, style({ transform: 'translateX(-100%)' }))
      ]),
    ]),
    trigger('slideInOut4', [
      transition(':enter', [
        style({ transform: 'translateX(-500px)' }),
        animate(200, keyframes([
          style({
            opacity: 0,
            offset: 0.375
          }),
          style({
            transform: 'translateX(-50px)',
            offset: 0.6
          }),
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            offset: 1
          })
        ]))
      ]),
      transition(':leave', [
        animate(2000, style({ transform: 'translateX(-100%)' }))
      ]),
    ]),
    trigger('slideInOut5', [
      transition(':enter', [
        style({ transform: 'translateX(-500px)' }),
        animate(200, keyframes([
          style({
            opacity: 0,
            offset: 0.375
          }),
          style({
            transform: 'translateX(-50px)',
            offset: 0.6
          }),
          style({
            transform: 'translateX(0%)',
            opacity: 1,
            offset: 1
          })
        ]))
      ]),
      transition(':leave', [
        animate(2000, style({ transform: 'translateX(-100%)' }))
      ]),
    ]),
    trigger('scrollAnimation', [
      state('show', style({
        opacity: 1,

        transform: "translateX(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateX(-100%)"
      })),
      transition('show => hide', animate('700ms ease-out')),
      transition('hide => show', animate('700ms ease-in'))
    ]),
  ]
})
export class CotizadorComponent implements OnInit, AfterViewInit {
    //el input sirve para saber si mostramos los botones de comprar o vender o ambos
    @Input() BuyingOrSelling: string;
    titleBuy: boolean = false;
    titleSell: boolean = false;
  /* varuiables compartidas */

  signupForm: FormGroup;
  pizarra = null;
  diccionario = [];
  monedasPrincipales = [];
  loading: boolean = false;
  showMaps: boolean = false;
  payerDni = '';
  payerName = '';
  userEmail = null;
  showModalStep4 = false;
  confirmarPassword = false;
  firstLegalOption = false;
  secondLegalOption = false;
  enableModalbuttonA = false;
  enableModalbuttonB = false;
  cash = true;  // si paga cash o no.
  transferIn = false; // si el cliente le va a hacer transferencia a Cambio posadas para pagar
  deliveryPay = false; // si el cliente quiere pagar con delivery
  delivery = false; //si el cliente queire recibir su dinero en su casa
  transferOut = false;  //si cambio posadas tiene que transferirle al cliente
  pickUp = true;  //si eligio retiro en sucursal
  finished = false; //finalizacion 
  userInput: any = 1; //importe ingresado del cliente
  userInputValue: any = 1; //importe ingresado del cliente Para visualizar
  userInputValueSell: any = 1;//importe ingresado del cliente Para visualizar
  userOutPutBuy = null; // resultado a pagar del cliente
  userOutPutTax = null;
  userOutPutSell = null; // resultado a pagar del cliente
  paymentMethod = false; // pantalla de metodos de pago
  pickUpDeliveryTransfer = false; // pantalla con las opciones delivery transferencia o sucursal
  cambioTransferData = false; // pnatalla de datos de transferencia de cambio posadas
  sucursales = false; // pantalla que muestra las sucursales el pickup
  sendHome = false; // pantalla que muestra la opcion de delivey
  transferInput = false;  // datos para completar por el cliente de su cuenta bancaria
  control = false;  // pantalla control
  public dateTime: Date;  // día mes año seleccionado 
  public dateHour: Date;  // hora seleccionada
  public today = new Date(); //dia minimo para elegir

  razonSocial = '';
  claveBancariaUniforme = '';
  cuit = '';
  alias = '';
  selectedStoreNumber = null; //numero de sucursal seleccionada
  selectedStoreName = '';// nombre de la sucursal seleccionada

  selectedRecievePlace = ''; //variable para asiganar el lugar seleccionada para el delivery 
  sucursalesGet = null; //Variable para mostrar las sucursales

  /*Google variables */
  public title = 'Places';
  public addrKeys: string[];
  public addr: any;


  /* variables para la compra */
  buy = true;
  iWantCurrencyId = 'USD';
  iRecieveCurrencyId = 'ARS';
  isUsdOrArs = false;
  buyingText = 'Dolar Estadounidense';
  buyingTextRecieve = 'Peso Argentino';
  selectBuyIWant = [];
  isBuying = false;



  /* variables para la venta */
  sell = false;
  isSelling = false;

  constructor(
    private generalService: GeneralService,
    private profileService: ProfileInfoService,
    private cService: CurrencyService,
    private customModalService: CustomModalService,
    private modalService: ModalService,
    private zone: NgZone,
    public el: ElementRef,
    private cp: MyCurrencyPipe,
    private router: Router,
    private cookiesService: CookiesService    
  ) {
    //esto hace que la navegacion sea pueda hacer como con anclas

    router.events.subscribe(s => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector("#" + tree.fragment);
          if (element) { element.scrollIntoView({ behavior: 'smooth' }); }
        }
      }
    });
  }

//    router.events.subscribe(s => {
//      if (s instanceof NavigationEnd) {
//        const tree = router.parseUrl(router.url);
//        if (tree.fragment) {
//          const element = document.querySelector("#" + tree.fragment);
//           console.log("1")
//           var elementPosition = element.getBoundingClientRect().top;
//          if (element) { 
//            console.log(elementPosition)
//           if(elementPosition > 0) { 
//           window.scrollTo({ top: elementPosition - 70,
//           behavior: 'smooth' });
//           }
//         }
//        }
//      }
//    });
//  }


  ngOnInit() {

    /*Calcula el dia de hoy y le saca un día para que funcione bien el minimo en el calendatio */
    this.today.setDate(this.today.getDate() - 1);
    this.today.setMinutes(0, 0, 0);

    this.cService.getBranches().subscribe(
      (response: any) => {
        console.log("ACA ESTA" + response);
      },
      (error) => {
        console.log(error);
      },
    );
    
    this.signupForm = new FormGroup({
      'password': new FormControl(null, [Validators.required]),
    });

    this.cService.getCurrencies().subscribe(
      (response: any) => {
        //console.log(response);
        this.settingDictionary(response);

        for (let i = 0; i < response.length; i++) {
          if (response[i].id != 'ARS') {
            this.selectBuyIWant.push({ 'id': response[i].id, 'name': response[i].name });
          }
        }

        //console.log(this.diccionario);

      },
      (error) => {
        console.log(error);
      }
    );
    //console.log(this.selectBuyIWant);
    this.calculateOnce(this.userInputValue, this.userInputValueSell);
    this.repeater();


      // para saber si esta comprando o vendiendo o ambas
      //console.log(this.BuyingOrSelling);
      this.BuyingOrSelling == 'buying' ? this.titleBuyTrue() : '';
      this.BuyingOrSelling == 'selling' ? this.titleSellTrue()  : '';
      this.BuyingOrSelling == 'both' ? this.bothTrue(): '';
      this.settingMainBtn();

  }
  settingMainBtn(who?){
    //console.log(who);
    if (this.BuyingOrSelling == 'buying') {
      document.getElementById('buyingButton').classList.add('btn-main');
      document.getElementById('sellngButton').classList.add('btn-hidden');
      this.buy = true;
      this.sell = false;
    } else if (this.BuyingOrSelling == 'selling') {
      document.getElementById('buyingButton').classList.add('btn-hidden');
      document.getElementById('sellngButton').classList.add('btn-main');
      document.getElementById('sellngButton').classList.add('active');
      this.buy = false;
      this.sell = true;

    } else if (this.BuyingOrSelling == 'both') {
      
      if (who == 'sell') {
        console.log('entro a setaer el main btn SELL');
        document.getElementById('buyingButton').classList.contains('active') ?  document.getElementById('buyingButton').classList.remove('active') : '';
        document.getElementById('sellngButton').classList.contains('btn-hidden') ?  document.getElementById('sellngButton').classList.remove('btn-hidden'): '';
        //document.getElementById('sellngButton').classList.contains('btn-main') ? '': document.getElementById('sellngButton').classList.add('btn-main');
        document.getElementById('sellngButton').classList.add('active');
        this.buy = false;
        this.sell = true;

      } else if(who == 'buy') {
        console.log('entro a setaer el main btn BUY ');
        document.getElementById('sellngButton').classList.contains('active') ?  document.getElementById('sellngButton').classList.remove('active'): '';
        document.getElementById('buyingButton').classList.contains('btn-hidden') ?  document.getElementById('buyingButton').classList.remove('btn-hidden'): '';
        
        //document.getElementById('buyingButton').classList.contains('btn-main') ? '': document.getElementById('buyingButton').classList.add('btn-main');
        document.getElementById('buyingButton').classList.add('active');
        this.buy = true;
        this.sell = false;
      }
    }
  }
  bothTrue(){
    this.titleBuyTrue();
    this.titleSellTrue();
  }
  titleBuyTrue() {
    this.titleBuy = true;
  }
  titleSellTrue(){
    this.titleSell = true;
  }
  getUserMail() {
    this.profileService.getUserInfo().subscribe(
      (Response: any) => {
        this.userEmail = Response.username;
      }
    )
  }
 //Method to be invoked everytime we receive a new instance 
 //of the address object from the onSelect event emitter.
 setAddress(addrObj) {
   //We are wrapping this in a NgZone to reflect the changes
   //to the object in the DOM.
   this.zone.run(() => {
     this.addr = addrObj;
     this.addrKeys = Object.keys(addrObj);
     // console.log(this.addrKeys);
     // console.log(this.addr.formatted_address);
     this.selectedRecievePlace = this.addr.formatted_address;
   });
 }
 repeater() {
   setInterval(() => {
     this.cService.getCurrencies().subscribe(
       (response: any) => {
         //console.log('hola');
         this.settingDictionary(response);
       },
       (error) => {
         console.log(error);
       }
     );
   }, 120000);
 }
 settingDictionary(arr) {
   this.diccionario = [];
   this.monedasPrincipales = [];
   //console.log('entro al set Dictionary')
   for (let i = 0; i < arr.length; i++) {
     if (arr[i].id != 'USD' && arr[i].id != 'EUR' && arr[i].id != 'BRL' && arr[i].id != 'ARS') {
       this.diccionario.push({ 'id': arr[i].id, 'name': arr[i].name, 'ask': arr[i].providers[0].ask, 'bid': arr[i].providers[0].bid });
     } else if (arr[i].id != 'ARS') {
       this.monedasPrincipales.push({
         'id': arr[i].id,
         'name': arr[i].name,
         'askCambio': arr[i].providers[0].ask,
         'bidCambio': arr[i].providers[0].bid,
         'askBancos': arr[i].providers[1].ask,
         'bidBancos': arr[i].providers[1].bid,
         'askCasas': arr[i].providers[2].ask,
         'bidCasas': arr[i].providers[2].bid,
       });
     }
   }
 }
 /* INICIO LOGICA DE COMPRA DE MONEDA EXTRANJERA */
 iWant(event) {
   this.iWantCurrencyId = event.target.value;
   console.log(event.target.value);
   switch (event.target.value) {
     case 'USD':
       this.buyingText = 'Dolar Estadounidense';
       break;

     case 'EUR':
       this.buyingText = 'Euros';
       break;

     case 'ARS':
       this.buyingText = 'Pesos Argentinos';
       break;

     case 'BRL':
       this.buyingText = 'Reales';
       break;
     default:
       const result = this.selectBuyIWant.filter(element => element.id == event.target.value);
       this.buyingText = result[0].name;
       break;
   }
   if (this.iWantCurrencyId != this.iRecieveCurrencyId) {
     this.calculateOnce(this.userInput, this.userInputValueSell);
   } else {
     this.customModalService.setBehaviorView({
       modal: true,
       type: 'warning',
       title: 'Atención!',
       text: 'Por favor seleccionar monedas distintas.'
       ,timer: false
     });
     this.userOutPutSell = 0;
     this.userOutPutBuy = 0;
   }
 }
 iRecieve(event) {

   this.iRecieveCurrencyId = event.target.value;
   switch (event.target.value) {
     case 'USD':
       this.buyingTextRecieve = 'Dolar Estadounidense';
       break;

     case 'EUR':
       this.buyingTextRecieve = 'Euros';
       break;

     case 'ARS':
       this.buyingTextRecieve = 'Pesos Argentinos';
       break;

     case 'BRL':
       this.buyingTextRecieve = 'Reales';
       break;

     default:
       const result = this.selectBuyIWant.filter(element => element.id == event.target.value);
       this.buyingTextRecieve = result[0].name;
       break;
   }
   if (this.iWantCurrencyId != this.iRecieveCurrencyId) {
     this.calculateOnce(this.userInput, this.userInputValueSell);
   } else {
    this.customModalService.setBehaviorView({
      modal: true,
      type: 'warning',
      title: 'Atención!',
      text: 'Por favor seleccionar monedas distintas.',
      timer: false
    });
     this.userOutPutSell = 0;
     this.userOutPutBuy = 0;
   }

 }

 /*Primer calculo nomas */
 calculateOnce(buy, sell) {
   
   let buyValue = buy;
   let sellValue = sell;
   if (buyValue < 0) {
     return true;
   }
   if (sellValue < 0) {
     return true;
   }

   this.cService.calculateRateBuy(this.iWantCurrencyId, this.iRecieveCurrencyId).subscribe(
     (response: any) => {
       //console.log('reinicio de la compra');
       //console.log(response);
       this.userOutPutBuy = buyValue * response.ask;
       this.userOutPutTax = this.userOutPutBuy * 0.30;
     },
     (error) => {
       console.log(error);
     }
   );

   this.cService.calculateRateBuy(this.iWantCurrencyId, this.iRecieveCurrencyId).subscribe(
     (response: any) => {
       //console.log(response);
       this.userOutPutSell = sellValue * response.bid;
       this.userOutPutTax = this.userOutPutSell * 0.30;
     },
     (error) => {
       console.log(error);
     }
   );


 }

 onSubmit() {
  const val = this.signupForm.value;
  if (this.userEmail && val.password) {
    this.generalService.getOauth(this.userEmail, val.password)
      .subscribe(
        (__r: any) => {
          console.log(__r);
          this.changeStep(9, 'buying');
          this.cookiesService.setCookie(__r.access_token);
          
          //chequea que no pasen mas de 15 minutos
          this.cookiesService.setCookieInterval();

          this.generalService.setBearerToken(__r.access_token);
          this.generalService.setUserData();
          const user = this.generalService.getUserData();
         
        
        },
        (error) => {
          console.log(error);
          this.customModalService.setBehaviorView({
            modal: true,
            title: 'Atención!', 
            text: 'La clave no es correcta. Por favor revisá los datos ingresados',
            type: 'warning',
            timer: false
        });
        }
      );
  }
}
 /*Hace el calculo para saber la cotizacion de las monedas seleccionadas */
 calculateBuyRate(event) {

   let value = Number(this.cp.parse(event, 3));
   this.userInput = value;
  //  console.log('evento: ', event);
  //  console.log('valor: ', value);
  //  console.log('userInput: ', this.userInput);

   if (value < 0) {
     return true;
   }
   if (this.buy) {
     this.cService.calculateRateBuy(this.iWantCurrencyId, this.iRecieveCurrencyId).subscribe(
       (response: any) => {
         this.userOutPutBuy = value * response.ask;
         this.userOutPutTax = this.userOutPutBuy * 0.30;
       },
       (error) => {
         console.log(error);
       }
     );

   }
   if (this.sell) {
     this.cService.calculateRateBuy(this.iWantCurrencyId, this.iRecieveCurrencyId).subscribe(
       (response: any) => {
         //console.log(response);
         this.userOutPutSell = value * response.bid;
         this.userOutPutTax = this.userOutPutSell * 0.30;
       },
       (error) => {
         console.log(error);
       }
     );
   }

 }

 modalLegalConfirmA(event) {
  if(event.target.value != ""){
    this.enableModalbuttonA = true;
  } else {
    this.enableModalbuttonA = false;
  }
}

modalLegalConfirmB(event) {
  if(event.target.value !== ""){
    this.enableModalbuttonB = true;
  } else {
    this.enableModalbuttonB = false;
  }
}

firstLegalSelect(event) {
    this.firstLegalOption = true;
}

secondLegalSelect(event) {
    this.secondLegalOption = true; 
}

closeLegalModal(){
  this.showModalStep4 = false;
}



 /*Selecciona una sucursal para hacer el retiro de plata */
selectedStore(event) {
  //console.log(event.target.selectedOptions[0].innerText)
  this.selectedStoreNumber = event.target.value;
  this.selectedStoreName = event.target.selectedOptions[0].innerText
 }

 changeStep(stepNumber, whatAreYouDoing) {
   switch (stepNumber) {
      case 1:
       if (this.iWantCurrencyId == this.iRecieveCurrencyId) { return true }
       this.loading = true;
       if (!this.generalService.isLogedIn()) {
         this.loading = false;
         this.customModalService.setBehaviorView({
          modal: true,
          type: 'warning',
          title: 'Atención!',
          text: 'Para poder operar debe iniciar sesión o registrarse.',
          timer: false
        });
        this.modalService.setBehaviorView({modal: true});
        return true;
       }
       this.generalService.getUserAuth().subscribe(
         (response: any) => {
           //console.log(response);
           this.loading = false;
           if (response.profileComplete) {
             this.payerDni = response.dni; //Guardamos el dni del usuario
             this.payerName = response.name + ' ' + response.lastName; // Guardamos el Nombre completo del usuario               
             this.setWidth(20);
             whatAreYouDoing == 'buying' ? this.isBuying = true : this.isSelling = true;
             if (this.isBuying) {
               document.getElementById('buyingButton').classList.add('btn-main');
               document.getElementById('sellngButton').classList.add('btn-hidden');
             } else {
               document.getElementById('buyingButton').classList.add('btn-hidden');
               document.getElementById('sellngButton').classList.add('btn-main');
             }
             this.paymentMethod = true;
             this.buy = false;
             this.sell = false;
             if (this.iRecieveCurrencyId == 'ARS' ) { // || this.iRecieveCurrencyId == 'USD'
               this.isUsdOrArs = true;
             } else {
               this.isUsdOrArs = false;
             }
           } else {
            this.router.navigate(['perfil']);
            this.customModalService.setBehaviorView({
              modal: true,
              type: 'warning',
              title: 'Atención!',
              text: 'Para poder operar debe completar su usuario.',
              timer: false
            });
           }
         },
         (error) => {
          this.loading = false;
           console.log(error);
         }
       );

       break;
     case 2:
       this.paymentMethod = false;

       if (this.cash) {
         this.pickUpDeliveryTransfer = true;
         this.setWidth(40);
       }
       if (this.transferIn) {
         this.cambioTransferData = true;
         this.setWidth(40);
       }
       if (this.deliveryPay) {
         this.sendHome = true;
         //this.cash = true;// esto es lo que hace que funcione el delivery porque en realidad paga con efectivo
         this.setWidth(40);
       }
       break;
     case 22:
       this.customModalService.setBehaviorView({
         modal: true,
         type: 'warning',
         title: 'Atención!',
         text: 'La transferencia tiene que estar a nombre del usuario registrado!',
         timer: false
       });

       this.cambioTransferData = false;
       this.pickUpDeliveryTransfer = true;
       break;

     case 3:
       this.pickUpDeliveryTransfer = false;
       this.setWidth(60);
       if (this.delivery && !this.deliveryPay) {
         this.sendHome = true;
       }
       if (this.delivery && this.deliveryPay) {
         this.setWidth(80);
         //this.alertTimeWarning();
         this.control = true;
       }
       if (this.transferOut) {
         /*Pantalla que el usuario completa con sus datos bancarios */
         this.transferInput = true;
         this.setWidth(60);
       }
       if (this.pickUp) {
         this.sucursales = true;
         this.cService.getBranches().subscribe(
           (response: any) => {
             //console.log(response);
             this.sucursalesGet = response;
             this.setWidth(60);
           },
           (error) => {
             console.log(error);
           },
         );
       }
       break;
     case 4:
       if (this.validatePickUpmethod()) {
         this.transferInput = false;
         this.sucursales = false;
         this.sendHome = false;
         this.control = true;
         //this.alertTimeWarning();
         this.setWidth(80);
       }

       break;

      case 44:
        if (this.deliveryPay && this.validatePickUpmethod()) {
          this.sendHome = false;
          this.transferInput = false;
          this.sucursales = false;
          this.pickUpDeliveryTransfer = true;
          this.setWidth(60);
          return true;
        }
        if (this.validatePickUpmethod()) {
          this.transferInput = false;
          this.sucursales = false;
          this.sendHome = false;
          this.control = true;
          //this.alertTimeWarning()
          this.setWidth(80);
        }

      break;

      case 5:
        this.confirmRequest()
      break;
     
      case 6:
        this.setWidth(100);
        this.control = false;
        this.finished = true;

      break;

      case 7:
        if (this.validatePickUpmethod()) {
          this.showModalStep4 = true;
        }
      break;

      case 8:
          this.getUserMail();
          this.transferInput = false;
          this.showModalStep4 = false;
          this.confirmarPassword = true;
      break;

      case 9:
        this.confirmarPassword = false;  
      this.control = true;
        
      default:
       break;
   }

 }
 stepBack(stepNumber) {
   switch (stepNumber) {
     case 0:
       this.isBuying ? this.buy = true : this.sell = true;
       this.isBuying ? this.resetBuy() : this.resetSell();
       this.paymentMethod = false;
       this.isUsdOrArs = false;
       this.isBuying = false;
       this.isSelling = false;
       this.setWidth(0);
      //  document.getElementById('buyingButton').classList.contains('btn-main') ? document.getElementById('buyingButton').classList.remove('btn-main') : '';
      //  document.getElementById('buyingButton').classList.contains('btn-hidden') ? document.getElementById('buyingButton').classList.remove('btn-hidden') : '';
      //  document.getElementById('sellngButton').classList.contains('btn-main') ? document.getElementById('sellngButton').classList.remove('btn-main') : '';
      //  document.getElementById('sellngButton').classList.contains('btn-hidden') ? document.getElementById('sellngButton').classList.remove('btn-hidden') : '';


       break;
     case 1:
       this.paymentMethod = true;
       this.cambioTransferData = false;
       this.cash = true;
       this.transferIn = false;
       this.pickUpDeliveryTransfer = false;
       this.deliveryPay = false;
       this.setWidth(20);
       break;

     case 2:
       this.confirmarPassword = false;
       this.pickUpDeliveryTransfer = true;
       this.pickUp = true;
       this.transferOut = false;
       this.delivery = false;
       this.sendHome = false;
       this.transferInput = false;
       this.sucursales = false;
       this.sucursalesGet = null;
       this.claveBancariaUniforme = '';
       this.alias = '';
       this.razonSocial = '';
       this.cuit = '';
       this.selectedRecievePlace = '';
       this.selectedStoreNumber = null; //numero de sucursal seleccionada
       this.selectedStoreName = '';// nombre de la sucursal seleccionada
       this.setWidth(40);
       break;

     case 22:
       if (this.deliveryPay) {
         console.log('caso 22 es deliverypay');
         this.paymentMethod = true;
         //this.cash = false;       
         this.cambioTransferData = false;
         this.transferIn = false;
         this.transferInput = false;
         this.sendHome = false;
         this.sucursales = false;
         this.sucursalesGet = null;
         this.claveBancariaUniforme = '';
         this.alias = '';
         this.razonSocial = '';
         this.cuit = '';
         this.selectedRecievePlace = '';
         this.selectedStoreNumber = null; //numero de sucursal seleccionada
         this.selectedStoreName = '';// nombre de la sucursal seleccionada
         this.setWidth(20);

       } else {
         console.log('caso 22 No es deliverypay');
         this.pickUpDeliveryTransfer = true;
         this.pickUp = true;
         this.transferOut = false;
         this.delivery = false;
         this.sendHome = false;
         this.transferInput = false;
         this.sucursales = false;
         this.sucursalesGet = null;
         this.claveBancariaUniforme = '';
         this.alias = '';
         this.razonSocial = '';
         this.cuit = '';
         this.setWidth(40);
       }
       break;
     case 3:
       if (this.delivery && !this.deliveryPay) {
         this.transferInput = false;
         this.sucursales = false;
         this.sendHome = true;
         this.control = false;

         this.setWidth(60);

       } else if (this.transferOut) {
         this.transferInput = true;
         this.sucursales = false;
         this.sendHome = false;
         this.control = false;
         this.setWidth(60);

       } else if (this.pickUp) {
         this.transferInput = false;
         this.sucursales = true;
         this.sendHome = false;
         this.control = false;
         this.setWidth(60);

       } else if (this.deliveryPay) {
         this.transferInput = false;
         this.sucursales = false;
         this.sendHome = false;
         this.control = false;
         this.pickUpDeliveryTransfer = true;
         this.selectedRecievePlace = '';
         this.setWidth(40);
       }

       break;

     default:
       break;
   }
 }
 setWidth(x) {
   this.classlistRemoveWidth();
   let buyButton = document.getElementById('buyingButton').firstElementChild;
   let sellButton = document.getElementById('sellngButton').firstElementChild;
   if (this.BuyingOrSelling == 'both') {
    buyButton.classList.add('width' + x);
    sellButton.classList.add('width' + x);
   } else if (this.BuyingOrSelling == 'selling'){
    sellButton.classList.add('width' + x);
   } else if (this.BuyingOrSelling == 'buying'){
    buyButton.classList.add('width' + x);
   }
  
 }
 classlistRemoveWidth() {
   let buyButton = document.getElementById('buyingButton').firstElementChild;
   let sellButton = document.getElementById('sellngButton').firstElementChild;
   buyButton.classList.contains('width0') ? buyButton.classList.remove('width0') : '';
   buyButton.classList.contains('width10') ? buyButton.classList.remove('width10') : '';
   buyButton.classList.contains('width20') ? buyButton.classList.remove('width20') : '';
   buyButton.classList.contains('width30') ? buyButton.classList.remove('width30') : '';
   buyButton.classList.contains('width40') ? buyButton.classList.remove('width40') : '';
   buyButton.classList.contains('width50') ? buyButton.classList.remove('width50') : '';
   buyButton.classList.contains('width60') ? buyButton.classList.remove('width60') : '';
   buyButton.classList.contains('width70') ? buyButton.classList.remove('width70') : '';
   buyButton.classList.contains('width80') ? buyButton.classList.remove('width80') : '';
   buyButton.classList.contains('width80') ? buyButton.classList.remove('width90') : '';
   buyButton.classList.contains('width100') ? buyButton.classList.remove('width100') : '';

   sellButton.classList.contains('width0') ? sellButton.classList.remove('width0') : '';
   sellButton.classList.contains('width10') ? sellButton.classList.remove('width10') : '';
   sellButton.classList.contains('width20') ? sellButton.classList.remove('width20') : '';
   sellButton.classList.contains('width30') ? sellButton.classList.remove('width30') : '';
   sellButton.classList.contains('width40') ? sellButton.classList.remove('width40') : '';
   sellButton.classList.contains('width50') ? sellButton.classList.remove('width50') : '';
   sellButton.classList.contains('width60') ? sellButton.classList.remove('width60') : '';
   sellButton.classList.contains('width70') ? sellButton.classList.remove('width70') : '';
   sellButton.classList.contains('width80') ? sellButton.classList.remove('width80') : '';
   sellButton.classList.contains('width80') ? sellButton.classList.remove('width90') : '';
   sellButton.classList.contains('width100') ? sellButton.classList.remove('width100') : '';

 }
 validatePickUpmethod() {

   let who = '';
   let answer = false;
   if (this.transferInput) {
     //console.log('es transferinput');
     who = 'transferInput';
     console.log(who);
   } else if (this.sucursales) {
     //console.log('es sucursal');
     who = 'sucursales';
     console.log(who);
   } else if (this.sendHome) {
     //console.log('es delivery');
     who = 'sendHome';
     console.log(who);
   }
   switch (who) {
     case 'transferInput':
       if (
         this.claveBancariaUniforme.length == 22 &&
         this.razonSocial.length > 0 &&
         this.cuit.length == 11
       ) {
         answer = true;
       } else if (
         this.razonSocial.length > 0 &&
         this.cuit.length == 11 &&
         this.alias.length > 0
       ) {
         answer = true;
       }
       let text = '';
       let send = false;



      if (this.alias.length == 0 && this.claveBancariaUniforme.length != 22) {
        text = text + 'Por favor completar el Alias o CBU. El CBU debe tener 22 digitos. ';
        send = true;
      }
      if (this.claveBancariaUniforme.length != 22) {
        text = text + 'El CBU tiene que tener 22 digitos. ';   
        send = true;     
      }
      if (this.cuit.length != 11) {
        text = text + 'El CUIT/CUIL debe tener 11 digitos. ';
        send = true;
      }

      if (this.razonSocial.length == 0) {
        text = text + 'Por favor completar la Razón Social/ Nombre. ';
        send = true;
      }

      if (send) {
        swal.fire({
        type: 'error',
        title: 'Atención',
        text: text
        });
        return false; 
      }

      break;

     case 'sendHome':
       if (this.addr.formatted_address.length != undefined) {
         if (
           this.addr.formatted_address.length > 0 &&
           this.dateHour != undefined &&
           this.dateTime != undefined &&
           this.selectedRecievePlace != ''
         ) {
           const day = this.dateHour.getHours();
           if(day < 10 || day > 18){
            this.customModalService.setBehaviorView({
              modal: true,
              type: 'warning',
              title: 'Horarios:',
              text: 'Lunes a vienres de 10:00 a 18:00',
              timer: false
            });            
           }else{
             answer = true;
           }            
         }
       }

       break;
     case 'sucursales':
       
       if (
         this.selectedStoreNumber != null &&
         this.dateHour != undefined &&
         this.dateTime != undefined
       ) {
         answer = true;
       }


       break;
     default:
       break;
   }
   return answer;
 }
 cashOrTransfer(what) {
   if (what == 'cash') {
     this.cash = true;
     this.transferIn = false;
     this.deliveryPay = false;
   }
   if (what == 'transferIn') {
     this.cash = false;
     this.transferIn = true;
     this.deliveryPay = false;
   }
   if (what == 'deliveryPay') {
     this.cash = false;
     this.transferIn = false;
     this.deliveryPay = true;
   }
 }
 transferOutDEliveryPickup(what) {
   if (what == 'pickUp') {
     this.pickUp = true;
     this.transferOut = false;
     this.delivery = false;
   }
   if (what == 'transferOut') {
     this.pickUp = false;
     this.transferOut = true;
     this.delivery = false;
   }
   if (what == 'delivery') {
     this.pickUp = false;
     this.transferOut = false;
     this.delivery = true;
   }
 }
 resetBuy() {  
     this.userInputValue = 1;
     this.buy = true;
     this.sell = false;
     this.iWantCurrencyId = 'USD';
     this.iRecieveCurrencyId = 'ARS';
     this.calculateOnce(1, 1);
     // Esto tiene que pasar en orden primero ^^
     this.setWidth(0);
     this.cash = true;
     this.transferIn = false;
     this.delivery = false;
     this.deliveryPay = false;
     this.transferOut = false;
     this.pickUp = true;
     this.finished = false;
     this.paymentMethod = false;
     this.pickUpDeliveryTransfer = false;
     this.cambioTransferData = false;
     this.sucursales = false;
     this.sendHome = false;
     this.transferInput = false;
     this.control = false;
     this.selectedRecievePlace = '';
     this.selectedStoreNumber = null; //numero de sucursal seleccionada
     this.selectedStoreName = '';// nombre de la sucursal seleccionada
     this.confirmarPassword = false;
     this.isUsdOrArs = false;
     this.buyingText = 'Dolar Estadounidense';
     this.buyingTextRecieve = 'Peso Argentino';
     this.isBuying = false;
     this.isSelling = false;
     this.sucursalesGet = null;
     this.claveBancariaUniforme = '';
     this.alias = '';
     this.razonSocial = '';
     this.cuit = '';
     if (this.BuyingOrSelling == 'both') {
      this.resetBuyingButton();
      this.resetSellingButton();
      this.settingMainBtn('buy');
      
     } else if (this.BuyingOrSelling == 'selling'){
      this.resetSellingButton();
      this.settingMainBtn('sell');
     } else if (this.BuyingOrSelling == 'buying'){
      this.resetBuyingButton();
      this.settingMainBtn('buy');
     }
     
     

   

 }
 resetSell() {
     this.userInputValueSell = 1;
     this.sell = true;
     this.buy = false;
     this.iWantCurrencyId = 'USD';
     this.iRecieveCurrencyId = 'ARS';
     this.calculateOnce(1, 1);
     // Esto tiene que pasar en orden primero ^^

     this.setWidth(0);
     this.cash = true;
     this.transferIn = false;
     this.delivery = false;
     this.deliveryPay = false;
     this.transferOut = false;
     this.pickUp = true;
     this.finished = false;
     this.paymentMethod = false;
     this.pickUpDeliveryTransfer = false;
     this.cambioTransferData = false;
     this.sucursales = false;
     this.sendHome = false;
     this.transferInput = false;
     this.control = false;
     this.selectedRecievePlace = '';
     this.selectedStoreNumber = null; //numero de sucursal seleccionada
     this.selectedStoreName = '';// nombre de la sucursal seleccionada

     this.isUsdOrArs = false;
     this.buyingText = 'Dolar Estadounidense';
     this.buyingTextRecieve = 'Peso Argentino';

     this.isBuying = false;
     this.isSelling = false;
     this.sucursalesGet = null;
     this.claveBancariaUniforme = '';
     this.alias = '';
     this.razonSocial = '';
     this.cuit = '';
     if (this.BuyingOrSelling == 'both') {
      this.resetBuyingButton();
      this.resetSellingButton();
      this.settingMainBtn('sell');
      
     } else if (this.BuyingOrSelling == 'selling'){
      this.resetSellingButton();
      this.settingMainBtn('sell');
     } else if (this.BuyingOrSelling == 'buying'){
      this.resetBuyingButton();
      this.settingMainBtn('buy');
     }
 }

 resetBuyingButton(){
  document.getElementById('buyingButton').classList.contains('btn-main') ? document.getElementById('buyingButton').classList.remove('btn-main') : '';
  document.getElementById('buyingButton').classList.contains('btn-hidden') ? document.getElementById('buyingButton').classList.remove('btn-hidden'): '';
  document.getElementById('buyingButton').classList.contains('active') ? document.getElementById('buyingButton').classList.add('active') : '';
  
 }
 resetSellingButton(){
  document.getElementById('sellngButton').classList.contains('btn-main') ? document.getElementById('sellngButton').classList.remove('btn-main') : '';
  document.getElementById('sellngButton').classList.contains('btn-hidden') ? document.getElementById('sellngButton').classList.remove('btn-hidden'):'';
  document.getElementById('sellngButton').classList.contains('active') ? document.getElementById('sellngButton').classList.remove('active') : '';
  
 }
 confirmRequest() {
   this.loading = true;
   if (this.pickUp) {
     let depositByWayOf = null;
     if (this.cash) {
       depositByWayOf = 'CASH';
     }
     else if (this.transferIn) {
       depositByWayOf = 'TRANSFER';
     } else if (this.deliveryPay) {
       depositByWayOf = 'DELIVERY';
     }
     let pickUpDateTime = this.dateTime.toJSON().substr(0, 10) + ' ' + this.dateHour.toTimeString().substr(0, 5);
     //console.log(this.dateTime.toISOString());
     let userOutPut = '';
     this.isBuying ? userOutPut = this.userOutPutBuy : userOutPut = this.userOutPutSell;
     this.cService.confirmTransactionByBranch(this.deliveryPay, this.payerDni, this.payerName, this.userInput, userOutPut, this.selectedStoreNumber, this.iWantCurrencyId, this.iRecieveCurrencyId, depositByWayOf, pickUpDateTime, this.isBuying, 'BRANCH',this.selectedRecievePlace).subscribe(
       (response: any) => {
         //console.log(response);
         let what = '';
         this.isBuying ? what = 'buying' : what = 'selling';
         this.loading = false;
         this.changeStep(6, what);
       },
       (error) => {
         console.log(error);
         this.loading = false;
         this.customModalService.setBehaviorView({
          modal: true,
          type: 'warning',
          title: 'Algo salió mal',
          text: 'Por favor volvé a intentar dentro de unos minutos.',
          timer: false
        });  
       },

     );
   }
   if (this.delivery) {
     let depositByWayOf = null;
     if (this.cash) {
       depositByWayOf = 'CASH';
     }
     else if (this.transferIn) {
       depositByWayOf = 'TRANSFER';
     } else if (this.deliveryPay) {
       depositByWayOf = 'DELIVERY';
     }
     let pickUpDateTime = this.dateTime.toJSON().substr(0, 10) + ' ' + this.dateHour.toTimeString().substr(0, 5);
     let userOutPut = '';
     this.isBuying ? userOutPut = this.userOutPutBuy : userOutPut = this.userOutPutSell;
     this.cService.confirmTransactionByDelivery(this.deliveryPay, this.payerDni, this.payerName, this.userInput, userOutPut, this.iWantCurrencyId, this.iRecieveCurrencyId, depositByWayOf, pickUpDateTime, this.isBuying, this.selectedRecievePlace, 'DELIVERY',this.selectedStoreNumber).subscribe(
       (response: any) => {
         //console.log(response);
         let what = '';
         this.isBuying ? what = 'buying' : what = 'selling';
         this.loading = false;
         this.changeStep(6, what);
       },
       (error) => {
         console.log(error);
         this.loading = false;
         
        this.customModalService.setBehaviorView({
          modal: true,
          type: 'warning',
          title: 'Algo salió mal',
          text: 'Por favor volvé a intentar dentro de unos minutos.',
          timer: false
        }); 
       },

     );
   }
   if (this.transferOut) {
     let depositByWayOf = null;
     if (this.cash) {
       depositByWayOf = 'CASH';
     }
     else if (this.transferIn) {
       depositByWayOf = 'TRANSFER';
     } else if (this.deliveryPay) {
       depositByWayOf = 'DELIVERY';
     }
     
     let pickUpDateTime = '';
     if (this.dateTime != undefined) {
       pickUpDateTime = this.dateTime.toJSON().substr(0, 10) + ' ' + this.dateHour.toTimeString().substr(0, 5);
     }      

     let userOutPut = '';
     this.isBuying ? userOutPut = this.userOutPutBuy : userOutPut = this.userOutPutSell;
     this.cService.confirmTransactionByTransfer(this.deliveryPay, this.payerDni, this.payerName, this.userInput, userOutPut, this.iWantCurrencyId, this.iRecieveCurrencyId, depositByWayOf, this.isBuying, this.alias, this.claveBancariaUniforme, this.cuit, this.razonSocial, pickUpDateTime, 'TRANSFER',this.selectedStoreNumber,this.selectedRecievePlace).subscribe(
       (response: any) => {
         //console.log(response);
         let what = '';
         this.isBuying ? what = 'buying' : what = 'selling';
         this.loading = false;
         this.changeStep(6, what);
       },
       (error) => {
         console.log(error);
         this.loading = false;
         this.customModalService.setBehaviorView({
          modal: true,
          type: 'warning',
          title: 'Algo salió mal',
          text: 'Por favor volvé a intentar dentro de unos minutos.',
          timer: false
        }); 
       },

     );
   }

 }
 /* FIN LOGICA DE COMPRA DE MONEDA EXTRANJERA */

 /*Validacion de que solo pued entrar numeros al input */
 numberOnly(event): boolean {
   const charCode = (event.which) ? event.which : event.keyCode;
   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
     return false;
   }
   return true;

 }
 public daysFilter = (d: Date): boolean => {
   const day = d.getDay();
   return day != 6 && day != 0;
 }
 // no se usa mas la funcion en este lugar
 public myFilter = (d: Date): boolean => {
   const day = d.getHours();
   // Prevent Saturday and Sunday from being selected.
   if (day < 9 || day > 18) {

    // ponerl la alerta de moadl personalizada
    alert('Lunes a vienres de 10:00 a 18:00');
    //  swal({
    //    type: 'info',
    //    title: 'Horarios:',
    //    text: 'Lunes a vienres de 10:00 a 18:00'
    //  });
   }
   return day >= 9 && day <= 18;
 }
 resetBuyOrSell() {
   this.isBuying ? this.resetBuy() : this.resetSell();
 }
 mapSwhitecher(boolean){
   boolean ? this.showMaps = true : this.showMaps = false;
 }
 alertTimeWarning() {   
     if ((this.dateTime.getTime() - (this.today.getTime() +86400000) ) > 86400000  ) {
       //console.log('es un dia mayor')
    this.customModalService.setBehaviorView({
      modal: true,
      type: 'warning',
      title: 'Atención',
      text: 'La cotización puede cambiar desde hoy a la fecha selecionada.',
      timer: false
    }); 
     }            
 }
 ngAfterViewInit() {
   //window.scrollTo(0, 0);
 }
}

