import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import  CustomModalObject from './custom-modal'; 

@Injectable({
  providedIn: 'root'
})
export class CustomModalService {
  private behave = new BehaviorSubject<CustomModalObject>({ modal: false, title: 'Test', type: 'error', text: 'Empty text',timer: false });
  constructor() { }
  setBehaviorView(behave: CustomModalObject) {
    this.behave.next(behave);
  }
  getBehaviorView(): Observable<any> {
    return this.behave.asObservable();
  }
  close(){
    const __obj = { modal: false, title: '', type: '', text: '',timer: false }
    this.behave.next(__obj);
  }

}
