import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {


    
  constructor(private http: HttpClient, private generalService: GeneralService) {
  }
  getCurrencies(){
    return this.http.get(this.generalService.url + '/public/currency/ARS' , { headers: this.generalService.basicHeaders } );
  }

  calculateRateBuy(iWant,iPay) {
    return this.http.get(this.generalService.url + '/public/currency/calculator?from='+iWant+'&to='+iPay , { headers: this.generalService.basicHeaders } );
  }

  getBranches() {
    return this.http.get(this.generalService.url + '/public/branch' , { headers: this.generalService.basicHeaders } );
  }
  getUserData(){
    return this.http.get(this.generalService.url + '/public/user/mine' , { headers: this.generalService.fixedHeader } );
  }
  updateUserData(obj){
    let formData =new FormData();
    obj.name != null ? formData.append('name', obj.name): ''; 
    obj.lastName != null ? formData.append('lastName', obj.lastName) : '';  
    obj.dni != null ? formData.append('DNI', obj.dni)  : ''; 
    obj.cuilCuitCdi != null ? formData.append('cuilCuitCdi', obj.cuilCuitCdi)  : '';
    obj.postalCode != null ? formData.append('postalCode', obj.postalCode)  : '';
    obj.province != null ? formData.append('province', obj.province)  : '';
    obj.city != null ? formData.append('city', obj.city)  : '';
    obj.address != null ? formData.append('address', obj.address)  : '';
    obj.phoneNumber != null ? formData.append('phoneNumber', obj.phoneNumber)  : '';
    obj.birthPlace != null ? formData.append('birthPlace', obj.birthPlace)  : '';
    obj.birthDate != null ? formData.append('birthDate', obj.birthDate)  : '';
    obj.userType != null ? formData.append('userType', obj.userType)  : '';
    obj.arrivalDate != null ? formData.append('arrivalDate', obj.arrivalDate)  : '';
    obj.civilStatus != null ? formData.append('civilStatus', obj.civilStatus)  : '';
    obj.pep != null ? formData.append('pep', obj.pep)  : '';
    obj.sujetoObligado != null ? formData.append('sujetoObligado', obj.sujetoObligado)  : '';
    obj.activityType != null ? formData.append('activityType', obj.activityType)  : '';
    obj.fileToUploadProfile != undefined ? formData.append('imageProfile', obj.fileToUploadProfile)  : '';
    obj.imageDniFront != undefined ? formData.append('dniFront', obj.imageDniFront)  : '';
    obj.imageDniBack != undefined ? formData.append('dniBack', obj.imageDniBack)  : '';
    //console.log(obj.fileToUploadProfile);
    return this.http.post(this.generalService.url + '/public/user/edit' ,formData, { headers: this.generalService.fixedHeader } );
    
  }
  getUserAlerts(){
    return this.http.get(this.generalService.url + '/public/alert' , { headers: this.generalService.fixedHeader } );
  }
  deleteAlert(id){
    return this.http.delete(this.generalService.url + '/public/alert?alertId='+id , { headers: this.generalService.fixedHeader } );
  }
  createAlert(currencyId, isBid ,value ){
    let formData = new FormData();
    formData.append('currencyId',currencyId );
    formData.append('isBid',isBid );
    formData.append('value',value );
    return this.http.post(this.generalService.url + '/public/alert',formData, { headers: this.generalService.fixedHeader } );
  }
  getUserTransactions(number) {
    return this.http.get(this.generalService.url + '/public/transaction?page='+number+'&sort=id,desc' , { headers: this.generalService.fixedHeader } );
  }
  confirmTransactionByBranch(
    deliveryPay,
    payerDni,
    payerName,
    userInputAmount,
    userOutputAmount,
    branchId,
    currencyFromId,
    currencyToId,
    depositByWayOf,
    pickUpDateTime,
    isBuying,
    withdrawByWayOf,
    selectedPlace ){
    let formData = new FormData();
    
    console.log(deliveryPay);
    // console.log(currencyFromId);
    // console.log(currencyToId);
    // console.log(depositByWayOf);
    // console.log(pickUpDateTime);
    formData.append('branchId',branchId);
    if (isBuying){
      formData.append('compraVentaStatus', 'COMPRA');
      formData.append('currencyFromId',currencyToId);
      formData.append('currencyToId',currencyFromId);
      formData.append('amount',userInputAmount);
    }else{
      formData.append('compraVentaStatus', 'VENTA');      
      formData.append('currencyFromId',currencyFromId);
      formData.append('currencyToId',currencyToId);
      formData.append('amount',userOutputAmount);
    }
    formData.append('depositByWayOf',depositByWayOf);
    formData.append('pickUpDateTime',pickUpDateTime);
    if (deliveryPay){
      formData.append('payDateTime', pickUpDateTime);
      formData.append('payerDni',payerDni);
      formData.append('payerName',payerName);
      formData.append('payerAddress',selectedPlace);
    }
    formData.append('withdrawByWayOf',withdrawByWayOf ); 

    return this.http.post(this.generalService.url + '/public/transaction/by_branch' ,formData, { headers: this.generalService.fixedHeader } );
  }
  confirmTransactionByDelivery(
    deliveryPay,
    payerDni,
    payerName,
    userInputAmount,
    userOutputAmount,
    currencyFromId,
    currencyToId,
    depositByWayOf,
    pickUpDateTime,
    isBuying,
    selectedPlace,
    withdrawByWayOf,
    branchId
      ){
    let formData = new FormData();
    
    // console.log(branchId);
    // console.log(currencyFromId);
    // console.log(currencyToId);
    // console.log(depositByWayOf);
    // console.log(pickUpDateTime);
    if (branchId != null && branchId != undefined && branchId != '' ) {
      formData.append('branchId', branchId);
    }else{
      formData.append('branchId', '2');//harcodeo para la sucursal que todavia falta ajustar.
    }

    if (isBuying){
      formData.append('compraVentaStatus', 'COMPRA');
      formData.append('currencyFromId',currencyToId);
      formData.append('currencyToId',currencyFromId);
      formData.append('amount',userInputAmount);
    }else{
      formData.append('compraVentaStatus', 'VENTA');      
      formData.append('currencyFromId',currencyFromId);
      formData.append('currencyToId',currencyToId);
      formData.append('amount',userOutputAmount);
    }
    formData.append('withdrawByWayOf',withdrawByWayOf);
    formData.append('depositByWayOf',depositByWayOf);
    formData.append('pickUpDateTime',pickUpDateTime);

    
    formData.append('receiverAddress',selectedPlace);
    formData.append('receiverDni',payerDni);
    formData.append('receiverName',payerName);
    if (deliveryPay){
      formData.append('payDateTime', pickUpDateTime);
      formData.append('payerDni',payerDni);
      formData.append('payerName',payerName);
      formData.append('payerAddress',selectedPlace);
    }


    return this.http.post(this.generalService.url + '/public/transaction/by_delivery' ,formData, { headers: this.generalService.fixedHeader } );
  }
  confirmTransactionByTransfer(
    deliveryPay,
    payerDni,
    payerName,
    userInputAmount,
    userOutputAmount,
    currencyFromId,
    currencyToId,
    depositByWayOf,
    isBuying,
    receiverAlias,
    receiverCbu,
    receiverCuit,
    receiverName,
    pickUpDateTime,
    withdrawByWayOf,
    branchId,
    selectedPlace){
    let formData = new FormData();
    console.log(currencyFromId);
    console.log(currencyToId);
    console.log(depositByWayOf);
    if (branchId != null && branchId != undefined && branchId != '' ) {
      formData.append('branchId', branchId);
    }
    // else{
    //   formData.append('branchId', '2');//harcodeo para la sucursal que todavia falta ajustar.
    // }
    if (isBuying){
      formData.append('compraVentaStatus', 'COMPRA');
      formData.append('currencyFromId',currencyToId);
      formData.append('currencyToId',currencyFromId);
      formData.append('amount',userInputAmount);
    }else{
      formData.append('compraVentaStatus', 'VENTA');      
      formData.append('currencyFromId',currencyFromId);
      formData.append('currencyToId',currencyToId);
      formData.append('amount',userOutputAmount);
    }
    formData.append('withdrawByWayOf',withdrawByWayOf);
    formData.append('depositByWayOf',depositByWayOf);
    if (deliveryPay){
      formData.append('payDateTime', pickUpDateTime);
      formData.append('payerDni',payerDni);
      formData.append('payerName',payerName);
      formData.append('payerAddress',selectedPlace);
      
    }
    
    formData.append('depositByWayOf',depositByWayOf);
    formData.append('receiverAlias',receiverAlias);
    formData.append('receiverCbu',receiverCbu);
    formData.append('receiverCuit',receiverCuit);
    formData.append('receiverName',receiverName);

    return this.http.post(this.generalService.url + '/public/transaction/by_transfer' ,formData, { headers: this.generalService.fixedHeader } );
  }
}
