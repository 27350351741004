import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { trigger, transition, keyframes, animate, style } from '@angular/animations';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from '../services/general.service';
import { CustomModalService } from '../services/custom-modal.service';
import { ProfileInfoService } from '../services/profile-info.service';
import { CookiesService } from '../services/cookies.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('* => *', [
        animate(700, keyframes([
          style({
            transform: 'scale(0)',
            opacity: 0,
            offset: 0
          }),
          style({
            transform: 'scale(0.5)',
            offset: 0.5
          }),
          style({
            transform: 'scale(1.15)',
            opacity: 1,
            offset: 0.75
          }),
          style({
            transform: 'scale(1)',
            opacity: 1,
            offset: 1
          })
        ]))
      ]),
    ]),
  ]
})
export class ModalComponent implements OnInit {
  // Formulario
  signupForm: FormGroup;

  // Formulario de registro
  registerForm: FormGroup;
  userType: string = '';

  // Muestra o no el overlay y el modal
  public show = false;

   // Muestra o no el overlay y el modal del recovery
  public showRecovery = false;

  // muestra la parte del login dentro del modal
  public showLogin = true;

  // muestra la primer parte del registro
  public showRegister1 = false;

  // muestra la segunda parte del registro
  public showRegister2 = false;
  
  // muestra modal de confirmar contraseña
  public confirmPass = false;

  //te deja ir al showRegister 2
  public completeRegister: boolean = false;

  @ViewChild('modal') modal: ElementRef;
  @ViewChild('register') registerChild: ElementRef;
  @ViewChild('login') loginChild: ElementRef;
  constructor(
    private modalService: ModalService,
    private customModal: CustomModalService,
    private profileService: ProfileInfoService,
    private router: Router,
    private gService: GeneralService,
    private cookiesService: CookiesService) {
  }

  ngOnInit() {
    this.signupForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, [Validators.required]),
    });
    this.registerForm = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'lastName': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required]),
      'password2': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required, Validators.email]),
    })

    this.modalService.getBehaviorView().subscribe(v => {
      this.show = v['modal'];
    });

    
  }


  overlayClicked(__event) {
    if(__event.path != undefined){
      if(__event.path.indexOf(this.modal.nativeElement) === -1){
        this.closeModal()
      }
    }else{
      __event.target.children[0] === this.modal.nativeElement ? this.closeModal(): '';
    }
    
  }
  recoveryClicked(){
    this.showRecovery = !this.showRecovery;
  }

  closeModal(){
      this.loginChild.nativeElement.classList.add('active');
      this.registerChild.nativeElement.classList.remove('active');
      this.setToFalse();
      this.showLogin = true;
      this.modalService.setBehaviorView({ modal: false });
      this.showRecovery = false;
  }
  changeMenus(ev) {

    if (ev == this.registerChild.nativeElement) {
      this.registerChild.nativeElement.classList.add('active');
      this.loginChild.nativeElement.classList.remove('active');
      this.setToFalse();
      this.showRegister1 = true;

    }
    if (ev == this.loginChild.nativeElement) {
      this.loginChild.nativeElement.classList.add('active');
      this.registerChild.nativeElement.classList.remove('active');
      this.setToFalse();
      this.showLogin = true;
    }

  }
  setToFalse() {
    this.showRegister1 = false;
    this.showRegister2 = false;
    this.showLogin = false;
    this.confirmPass = false;
  }
  next() {
    this.showRegister1 = false;
    this.showRegister2 = true;
  }
  
  onSubmit() {
    const val = this.signupForm.value;
    if (val.email && val.password) {
      this.gService.getOauth(val.email, val.password)
        .subscribe(
          (__r: any) => {
            console.log(__r);
            this.cookiesService.setCookie(__r.access_token);
            
            //chequea que no pasen mas de 15 minutos
            this.cookiesService.setCookieInterval();

            this.gService.setBearerToken(__r.access_token);
            this.gService.setUserData();
            this.modalService.setBehaviorView({ modal: false });
            const user = this.gService.getUserData();
            if (user.firstStartSession) {
              this.router.navigateByUrl('/firstLog');
              this.gService.changeFirstSessionStatus().subscribe(
                (__r) => { console.log(__r) },
                (error) => { console.log(error) }
              );
            } else {
              this.router.navigateByUrl('');
            }
          },
          (error) => {
            console.log(error);
            this.customModal.setBehaviorView({
              modal: true,
              title: 'Atención!', 
              text: 'No se puede iniciar sesión. Por favor revisá los datos ingresados',
              type: 'warning',
              timer: false
          });
          }
        );
    }
  }

  onForgottenPassword() {

    swal.showLoading();
    this.gService.userForgotPassword(this.signupForm.value.email).subscribe(
      (response: any) => {
        swal.close();
        swal.fire({
          type: 'info',
          title: 'Solicitud exitosa.',
          text: 'Te enviamos un mail con los pasos a seguir para poder reestablecer tu contraseña.'
        })
      },
      (error) => {
        swal.close();
        swal.fire({
          type: 'error',
          text: 'Por favor verifica el correo ingresado',
          title: 'Error'
        });
        console.log(error);
      }
    );

  }
  onRegister() {

    const val = this.registerForm.value;
    if (val.password != val.password2) {
      return this.customModal.setBehaviorView({ modal: true, title: 'Error', type: 'error', text: 'Las contraseñas deben coincidir.',timer: false });
    } else {
      this.profileService.createNewUser(val, this.userType).subscribe(
        (__r) => {
          //console.log(__r);
          this.modalService.setBehaviorView({ modal: false });
          this.customModal.setBehaviorView({ modal: true, title: 'Exito', type: 'success', text: 'Usuario creado exitosamente. Por favor revise la casilla de correo ingresada, para confirmar el registro.',timer: false });
          this.loginChild.nativeElement.classList.add('active');
          this.registerChild.nativeElement.classList.remove('active');
          this.setToFalse();
          this.showLogin = true;
          
        },
        (error) => {
          console.log(error);
          this.customModal.setBehaviorView({ modal: true, title: 'Error', type: 'error', text: 'Algo salió mal, por favor vuelva a intentar en unos minutos.',timer: false });
        }
      );
    }




  }
  inputChange(__event) {
    const value = __event.target.value;
    switch (value) {
      case 'habilitado':
        this.setUserType(value);
        break;
      case 'Negocio':
        this.customModal.setBehaviorView({
          modal: true, 
          title: 'Cambio Posadas', 
          text: 'Para darse de alta como Persona Jurídica llámenos al 0800-220-CAMBIO o escribanos a info@cambioposadas.com.ar, uno de nuestros ejecutivos de cuenta se contactará con ustedes a la brevedad para ofrecerle todas las opciones disponibles para cambiar divisas al mejor precio.', 
          type: 'success',
          timer: false
        });
        this.completeRegister = false;
        break;
      case 'Empresa':
        this.customModal.setBehaviorView({
          modal: true, 
          text: 'Para darse de alta como Persona Jurídica llámenos al 0800-220-CAMBIO o escribanos a info@cambioposadas.com.ar, uno de nuestros ejecutivos de cuenta se contactará con ustedes a la brevedad para ofrecerle todas las opciones disponibles para cambiar divisas al mejor precio.', 
          title: 'Cambio Posadas', 
          type: 'success',
          timer: false
        });
        this.completeRegister = false;
        break;

      default:
        break;
    }
  }
  setUserType(value) {
    this.completeRegister = true;
    this.userType = value;
  }
  get password() {
    return this.registerForm.get('password');
  }
  get password2() {
    return this.registerForm.get('password2');
  }

}
