import { Component, OnInit } from '@angular/core';
import { ContactFormService } from '../services/contact-form.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private contactService:ContactFormService) { }

  model:any = {}

  ngOnInit() {
  }

  onSubmit () {
    
    console.log(this.model);

    this.contactService.sendForm(this.model.name, this.model.email, this.model.phone, this.model.message).subscribe((__r) => {
      console.log(this.model.name, this.model.email, this.model.phone, this.model.message);
      console.log(__r)
      Swal.fire({
        customClass: 'modal-success',
        title: 'Gracias por contactarte.',
        text: 'Te responderemos a la brevedad.',
        type: 'success',
        confirmButtonText: 'Aceptar'
      });
    },
    (error) =>{
      Swal.fire({
        customClass: 'modal-error',
        title: 'Error',
        text: 'Algo salió mal, intentá nuevamente más tarde',
        type: 'warning',
        confirmButtonText: 'Aceptar'
      });
      console.log(error);
    });
     
  }

  
  
}
