import { Pipe, PipeTransform } from '@angular/core';
const PADDING = "00";
@Pipe({
  name: 'myCurrency'
})
export class MyCurrencyPipe implements PipeTransform {

 
  DECIMAL_SEPARATOR: string;
  THOUSANDS_SEPARATOR: string;

  constructor() {
    console.log('inicio el mycurrencypipe');
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = '.';
    this.THOUSANDS_SEPARATOR = ',';
  }

  transform(value: number | string, fractionSize: number = 2): string {
    const ds = '.';
    const ts = ',';
    let [integer, fraction = ''] = (value || '').toString()
        .split(ds);

    fraction = fractionSize > 0
        ? ds + (fraction + PADDING).substring(0, fractionSize)
        : '';

    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ts);
    integer = integer ? integer : '0';
    return integer + fraction;
}
parse(value: string, fractionSize: number = 3): string {
  const ds = '.';
  const ts = ',';
  let [integer, fraction = ''] = (value || '').toString().split(ds);

  integer = integer.replace(new RegExp(ts, 'g'), '');

  fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? ds + (fraction + PADDING).substring(0, fractionSize)
      : '';
  integer = integer ? integer : '0';
  return integer + fraction;
}

}
